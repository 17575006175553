import Vue from 'vue';
import firebase from 'firebase/app';
import { State } from '@/models/State';
import { ExtendedVue } from 'vue/types/vue';

/**
 * Binding a Firestore document at Component navigation
 * @param ref firestore reference
 * @param stateSlice part of the state to bind the document to
 * @param idName param key name to be taken from the route
 * @param options binding options
 * @param notFoundStopRedirect stop redirect via router
 */
export default (
  {
    ref,
    stateSlice,
    idName,
    options,
    notFoundStopRedirect,
  }:
  {
    ref: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>,
    stateSlice: keyof State,
    idName: string,
    options?: {
      maxRefDepth?: number,
      [key: string]: any,
    },
    notFoundStopRedirect?: boolean,
  },
): ExtendedVue<Vue, unknown, unknown, unknown, Record<never, any>> => Vue.extend({
  watch: {
    [`$route.params.${idName}`]: {
      async handler (newId): Promise<void> {
        if (newId) {
          // Bind
          const document = await this.$store.dispatch(
            'bindFirestoreReference',
            {
              name: stateSlice,
              ref: ref.doc(newId),
              ...options && { options },
            },
          );

          // Unbind
          this.$store.dispatch(
            'unbindFirestoreReference',
            { name: stateSlice, reset: false },
          );

          // Redirect
          if (!document && !notFoundStopRedirect) {
            this.$router.replace('/404');
          }
        }
      },
      immediate: true,
    },
  },
  beforeDestroy(): void {
    this.$store.dispatch(
      'resetStateSlice',
      { name: stateSlice },
    );
  },
});
